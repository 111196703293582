<template>
  <div>
    <b-card
      id="product-form-create"
      ref="product-form-create"
      no-body
      class="custom-border-top-1px"
    >
      <div class="card-header">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          THÔNG TIN CƠ BẢN
        </b-badge>
        <div class="d-flex flex-column">
          <b-button
            v-b-tooltip.hover="'Tỉnh, quận/huyện, phường/xã, đường, số nhà'"
            :variant="getStatusCheckDuplicateBtn()"
            size="sm"
            @click="handleCheckProductUnique"
          >
            <feather-icon icon="AlertCircleIcon" />
            Kiểm tra
          </b-button>
        </div>
      </div>
      <div class="card-body">
        <b-row>
          <b-col
            cols="12"
            lg="4"
          >
            <!--            Tên văn phòng-->
            <dynamic-form
              :id="'product-form-name'"
              :value="value.name"
              :type="'text'"
              placeholder="Tên văn phòng"
              :label="'Tên'"
              :class="'form-group-hor'"
              @update-value="val => value.name = val"
            />

            <!--              hình thức-->
            <dynamic-form
              v-show="false"
              :id="'product-form-hinh-thuc'"
              :value="value.formality"
              :type="'radio'"
              :label="'Hình thức'"
              :required="required.formality ? 'required' : ''"
              :class="'form-group-hor'"
              :options="FORMALITY_PRODUCT.filter(item => item.validBy.includes(productMenuTypeVI || 'cho-thue'))"
              @update-value="val => value.formality = val"
            />

            <!--              tỉnh-->
            <dynamic-form
              :id="'product-form-tinh'"
              :value="value.province_id"
              :type="'radio'"
              :label="'Tỉnh'"
              value-field="id"
              :required="required.province_id ? 'required' : ''"
              :class="'form-group-hor'"
              :options="PROVINCE"
              @update-value="val => value.province_id = val"
            />

            <!--              quận huyện-->
            <dynamic-form
              :id="'product-form-quanhuyen'"
              :value="value.district_id"
              :type="'select'"
              :label="'Quận/Huyện'"
              :required="required.district_id ? 'required' : ''"
              value-field="id"
              text-field="name"
              :class="'form-group-hor'"
              :options="districts"
              @update-value="val => value.district_id = val"
            />

            <!--              Phường/Xã-->
            <dynamic-form
              :id="'product-form-phuongxa'"
              :value="value.ward_id"
              :type="'select'"
              :label="'Phường/Xã'"
              :required="required.ward_id ? 'required' : ''"
              value-field="id"
              text-field="name"
              :class="'form-group-hor'"
              :options="wards"
              @update-value="val => value.ward_id = val"
            />

            <!--              Đường-->
            <dynamic-form
              :id="'product-form-duong'"
              :value="value.street_id"
              :type="'select'"
              :label="'Đường'"
              value-field="id"
              text-field="name"
              :required="required.street_id ? 'required' : ''"
              :select-default="false"
              :class="'form-group-hor'"
              :options="streets"
              @update-value="val => value.street_id = val"
            />

            <!--              Số nhà-->
            <dynamic-form
              :id="'product-form-sonha'"
              :value="value.house_number"
              :required="required.house_number ? 'houseNumber' : ''"
              :type="'text'"
              :label="'Số nhà'"
              append-icon="AlertCircleIcon"
              append-tooltip="12/1-12/3 <br/>
                              12A-12B-12C <br/>
                              12-14 <br/>
                              12Bis <br/>
                              12Ter <br/>
                              12A <br/>
                              12 <br/>
                              12/12 <br/>
                              242A-244 OR 242-244A <br/>
                              12-14-16 <br/>
                              12A1 <br/>
                              12A1 <br/>
                              A91/A92 <br/>
                              A91 <br/>
                              602/29C <br/>"
              :class="'form-group-hor'"
              @update-value="val => value.house_number = val"
            />
            <hr>
            <!--            Tên quản lý-->
            <dynamic-form
              :id="'product-form-chunha'"
              :value="value.host_name"
              :type="'text'"
              :label="'Tên quản lý'"
              placeholder="Họ tên"
              :required="required.host_name ? 'required' : ''"
              :class="'form-group-hor font-weight-bolder'"
              @update-value="val => value.host_name = val"
            />

            <!--            SĐT-->
            <dynamic-form
              :id="'product-form-sdt1'"
              :value="value.host_phone1"
              :type="'text'"
              :label="'SĐT'"
              placeholder="SĐT"
              :required="required.host_phone1 ? 'required' : ''"
              :class="'form-group-hor font-weight-bolder'"
              @update-value="val => value.host_phone1 = val"
            />

          </b-col>
          <b-col
            cols="12"
            lg="4"
          >

            <!--              Hướng-->
            <dynamic-form
              :id="'product-form-direction'"
              :value="value.direction"
              :type="'select'"
              :label="'Hướng'"
              :required="required.direction ? 'required' : ''"
              :class="'form-group-hor'"
              :select-default="false"
              :options="OFFICE_DIRECTION"
              @update-value="val => value.direction = val"
            />

            <!--              Hạng-->
            <dynamic-form
              :id="'product-form-class'"
              :value="value.class"
              :type="'select'"
              :label="'Hạng'"
              :required="required.class ? 'required' : ''"
              :class="'form-group-hor'"
              :select-default="false"
              :options="OFFICE_RANK"
              @update-value="val => value.class = val"
            />
            <!--            Số lầu-->
            <dynamic-form
              :id="'product-form-floor_number'"
              :value="value.floor_number"
              :type="'number'"
              placeholder="Số lầu"
              :label="'Số lầu'"
              :required="required.floor_number ? 'required' : ''"
              :class="'form-group-hor'"
              append-text="lầu"
              @update-value="val => value.floor_number = val"
            />

            <!--            Số hầm-->
            <dynamic-form
              :id="'product-form-basement_number'"
              :value="value.basement_number"
              :type="'number'"
              placeholder="Số hầm"
              :label="'Số hầm'"
              :required="required.basement_number ? 'required' : ''"
              :class="'form-group-hor'"
              append-text="hầm"
              @update-value="val => value.basement_number = val"
            />

            <!--            Số thang máy-->
            <dynamic-form
              :id="'product-form-elevator_number'"
              :value="value.elevator_number"
              :type="'number'"
              placeholder="Số thang máy"
              :label="'Số thang máy'"
              :required="required.elevator_number ? 'required' : ''"
              :class="'form-group-hor'"
              append-text="cái"
              @update-value="val => value.elevator_number = val"
            />
            <!--              Hoa  hồng-->
            <dynamic-form
              :id="'product-form-hoahong'"
              :value="value.commission"
              :type="'radio'"
              :label="'Hoa hồng'"
              :class="'form-group-hor'"
              :options="COMMISSION_OFFICE"
              :required="required.commission ? 'required' : ''"
              @update-value="val => value.commission = val"
            />

            <!--              Chi tiết hoa hồng TL-->
            <dynamic-form
              v-if="value.commission === 'TL' || value.commission === 'other'"
              :id="'product-form-chitiethoahongTL'"
              :value="value.input_tl"
              :type="'text'"
              :label="'Nội dung' + (value.commission === 'TL' ? ' TL' : ' khác')"
              :class="'form-group-hor'"
              @update-value="val => value.input_tl = val"
            />

            <!--            Thuê nguyên căn-->
            <dynamic-form
              :id="'filter-form-dacbiet'"
              :value="value.is_rent_all_apartment"
              :type="'checkbox'"
              :label="'Đặc biệt'"
              :sub-label="'Thuê nguyên căn'"
              :class="'form-group-hor'"
              @update-value="val => value.is_rent_all_apartment = val"
            />

            <!--            Nhân viên-->
            <dynamic-form
              :id="'product-form-ref'"
              :value="value.source_id"
              :type="'select'"
              :label="'Nguồn'"
              :options="users"
              text-field="fullname"
              value-field="id"
              :required="required.source_id ? 'required' : ''"
              :with-info="true"
              :select-default="false"
              placeholder="Nhân viên"
              :class="'form-group-hor'"
              @update-value="val => value.source_id = val"
            />

            <!--            Người hỗ trợ-->
            <dynamic-form
              :id="'product-form-contact'"
              :value="value.contact_id"
              :type="'select'"
              :label="'Người hỗ trợ'"
              :options="users"
              text-field="fullname"
              value-field="id"
              :required="required.contact_id ? 'required' : ''"
              :with-info="true"
              :select-default="false"
              placeholder="Nhân viên"
              :class="'form-group-hor'"
              @update-value="val => value.contact_id = val"
            />

          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <!--            Sàn điển hình-->
            <dynamic-form
              :id="'product-form-area_description'"
              :value="value.area_description"
              :type="'text'"
              placeholder="Sàn điển hình"
              :label="'Sàn điển hình'"
              :class="'form-group-hor'"
              @update-value="val => value.area_description = val"
            />

            <!--            Diện tích sàn-->
            <dynamic-form
              :id="'product-form-floor_area'"
              :value="value.floor_area"
              :type="'text'"
              placeholder="100,200,300"
              :label="'Diện tích sàn'"
              :required="required.floor_area ? 'required floorArea' : ''"
              :class="'form-group-hor'"
              @update-value="val => value.floor_area = val"
            />

            <!--            Mô tả diện tích-->
            <dynamic-form
              :id="'product-form-rental_area_description'"
              :value="value.rental_area_description"
              :type="'textarea'"
              rows="2"
              :label="'Mô tả diện tích'"
              :class="'form-group-hor'"
              @update-value="val => value.rental_area_description = val"
            />

            <!--            Giá thuê-->
            <dynamic-form
              :id="'product-form-input_price'"
              :value="value.input_price"
              :type="'number'"
              placeholder="USD"
              :label="'Giá thuê'"
              :required="required.input_price ? 'required' : ''"
              :class="'form-group-hor label-primary'"
              append-text="USD / m2"
              @update-value="val => value.input_price = val"
            />

            <!--            Phí dịch vụ-->
            <dynamic-form
              :id="'product-form-service_fee'"
              :value="value.service_fee"
              :type="'number'"
              placeholder="USD"
              :label="'Phí dịch vụ'"
              :class="'form-group-hor label-primary'"
              append-text="USD"
              @update-value="val => value.service_fee = val"
            />

            <!--            Giá mô tả-->
            <dynamic-form
              :id="'product-form-price_description'"
              :value="value.price_description"
              :type="'text'"
              placeholder="USD"
              :label="'Giá mô tả'"
              :class="'form-group-hor label-primary'"
              @update-value="val => value.price_description = val"
            />

            <!--            Giá trọn gói-->
            <dynamic-form
              :id="'product-form-price'"
              :value="value.price"
              :type="'text'"
              placeholder="USD"
              :label="'Giá trọn gói'"
              :class="'form-group-hor label-primary font-weight-bolder'"
              append-text="USD / m2"
              @update-value="val => value.price = val"
            />

          </b-col>
        </b-row>

        <small class="font-weight-bolder text-primary">Thông tin khác</small>
        <hr class="mt-0">

        <b-row>
          <b-col
            cols="12"
            sm="6"
            lg="4"
          >
            <!--            Phí oto-->
            <dynamic-form
              :id="'product-form-car_parking_fee'"
              :value="value.car_parking_fee"
              :type="'number'"
              placeholder="VNĐ"
              :label="'Phí ô-tô'"
              :class="'form-group-hor'"
              append-text="VNĐ/Xe/Tháng"
              @update-value="val => value.car_parking_fee = val"
            />
          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="4"
          >

            <!--            Phí xe máy-->
            <dynamic-form
              :id="'product-form-moto_parking_fee'"
              :value="value.moto_parking_fee"
              :type="'number'"
              placeholder="VNĐ"
              :label="'Phí mô-tô'"
              :class="'form-group-hor'"
              append-text="VNĐ/Xe/Tháng"
              @update-value="val => value.moto_parking_fee = val"
            />
          </b-col>
          <b-col
            cols="12"
            sm="6"
            lg="4"
          >

            <!--            phí ngoài giờ-->
            <dynamic-form
              :id="'product-form-overtime_fee_type'"
              :value="value.overtime_fee"
              :type="'number'"
              placeholder="Phí ngoài giờ"
              :sub-value="value.overtime_fee_type"
              :with-dropdown-options="OVERTIME_FEE_TYPE"
              :label="'Phí ngoài giờ'"
              :class="'form-group-hor'"
              :disabled="value.overtime_fee_type === 'thoa-thuan'"
              @update-value="val => value.overtime_fee= val"
              @update-sub-value="val => value.overtime_fee_type = val"
            />

          </b-col>
          <b-col
            cols="12"
            sm="6"
            lg="4"
          >

            <!--            Tiền điện-->
            <dynamic-form
              :id="'product-form-electricity_fee'"
              :value="value.electricity_fee"
              :type="'number'"
              placeholder="Tiền điện"
              :sub-value="value.electricity_fee_type"
              :with-dropdown-options="ELECTRICITY_FEE_TYPE"
              :label="'Tiền điện'"
              :class="'form-group-hor'"
              :disabled="value.electricity_fee_type === 'phi-nha-nuoc'"
              @update-value="val => value.electricity_fee = val"
              @update-sub-value="val => value.electricity_fee_type = val"
            />

          </b-col>
          <b-col
            cols="12"
            sm="6"
            lg="4"
          >

            <!--            Đặt cọc-->
            <dynamic-form
              :id="'product-form-deposit_time'"
              :value="value.deposit_time"
              :type="'number'"
              placeholder="Đặt cọc"
              :label="'Đặt cọc'"
              :class="'form-group-hor'"
              append-text="Tháng"
              @update-value="val => value.deposit_time = val"
            />

          </b-col>
          <b-col
            cols="12"
            sm="6"
            lg="4"
          >
            <!--            Thanh toán-->
            <dynamic-form
              :id="'product-form-pay_time'"
              :value="value.pay_time"
              :type="'number'"
              placeholder="Thanh toán"
              :label="'Thanh toán'"
              :class="'form-group-hor'"
              append-text="Tháng"
              @update-value="val => value.pay_time = val"
            />

          </b-col>
          <b-col
            cols="12"
            sm="6"
            lg="4"
          >
            <!--            Thời hạn thuê-->
            <dynamic-form
              :id="'product-form-lease_term'"
              :value="value.lease_term"
              :type="'number'"
              placeholder="Thời hạn thuê"
              :label="'Thời hạn thuê'"
              :class="'form-group-hor'"
              append-text="Năm"
              @update-value="val => value.lease_term = val"
            />

          </b-col>
          <b-col
            cols="12"
            sm="6"
            lg="4"
          >
            <!--            Thời gian trang trí-->
            <dynamic-form
              :id="'product-form-decor_time'"
              :value="value.decor_time"
              :type="'number'"
              placeholder="Thời gian"
              :label="'Trang trí'"
              :class="'form-group-hor'"
              append-text="Ngày"
              @update-value="val => value.decor_time = val"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BButton, BCard, BCol, BRow, VBTooltip,
} from 'bootstrap-vue'
import { computed, reactive, ref } from '@vue/composition-api'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import useProductOfficeCreate from '@/views/product-office/create/useProductOfficeCreate'
import store from '@/store'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BBadge,
    BCard,
    BCol,
    BRow,
    DynamicForm,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  computed: {
    houseStyleList() {
      const style = [
        this.value.terrace ? 'terrace' : '',
        this.value.basement ? 'basement' : '',
        this.value.badger ? 'badger' : '',
        this.value.elevator ? 'elevator' : '',
      ]
      return style || []
    },
    specialList() {
      const style = [
        this.value.is_hot ? 'is_hot' : '',
        this.value.has_corner ? 'has_corner' : '',
        this.value.show_in_web ? 'show_in_web' : '',
      ]
      return style || []
    },
  },
  watch: {
    value: {
      deep: true,
      handler(n) {
        if (!n) return
        this.formErrorText = undefined
        Object.keys(this.required).forEach(key => {
          if (!n[key]) {
            this.formErrorText = this.formErrorText || []
            this.formErrorText.push(this.required[key])
          }
        })
        this.$emit('update-form-status', this.formErrorText)
      },
    },
  },
  created() {
    this.$call(store.dispatch('app/fetchUsers')).then(res => {
      if (!res.data) return
      const user = getUserData()
      const found = res.data.find(item => item.id.toString() === user.id.toString())
      if (found !== undefined) {
        this.value.ref = found.id
        this.value.contact_id = found.id
      }
    })
  },
  methods: {
    removeRentalArea(index) {
      console.log(this.value.rental_area[index])
    },
    getStatusCheckDuplicateBtn() {
      switch (this.addressStatus) {
        case undefined:
        case null:
          return 'success'
        case '':
          return 'primary'
        default:
          return 'warning'
      }
    },
    handleCheckProductUnique() {
      if (!this.value.street_id) {
        this.$toastr('Vui lòng nhập đường', 'danger')
        return
      }
      this.$call(this.checkProductUnique(this.address, res => {
        const error = res.data.message || ''
        if (error) {
          this.duplicateError = error
          this.$toastr(error, 'warning')
        } else this.$showNoti('Địa chỉ sản phẩm là duy nhất')
        this.addressStatus = error || ''
        this.$emit('handle-duplicate-error', error)
      }))
    },
    updateByCheckboxGroup(options, checkedList) {
      if (!options.length) return
      options.forEach(item => {
        this.value[item.value] = checkedList.includes(item.value)
      })
    },
  },
  setup(props) {
    const {
      productMenuTypeVI, districts, wards, streets,
      checkProductUnique,
    } = useProductOfficeCreate()

    const users = computed(() => store.state.app.users)

    const required = reactive({
      formality: 'Hình thức là bắt buộc',
      province_id: 'Tỉnh là bắt buộc',
      district_id: 'Quận/huyện là bắt buộc',
      ward_id: 'Phường/xã là bắt buộc',
      street_id: 'Đường là bắt buộc',
      house_number: 'Số nhà không đúng định dạng',
      floor_number: computed(() => (props.value.type !== 'san-thuong' ? 'Số lầu là bắt buộc' : '')),
      room_number_total: computed(() => (props.value.type === 'khach-san' ? 'Số phòng là bắt buộc' : '')),
      floor_area: 'Diện tích sàn là bắt buộc',
      direction: 'Hướng là bắt buộc',
      class: 'Hạng là bắt buộc',
      input_price: 'Giá thuê là bắt buộc',
      transfer_price: computed(() => (props.value.formality === 'sang-nhuong' ? 'Giá sang nhượng là bắt buộc' : '')),
      host_name: 'Tên quản lý là bắt buộc',
      host_phone1: 'SĐT quản lý là bắt buộc',
      commission: 'Hoa hồng là bắt buộc',
    })

    const tooltipPriceDisplayCustomer = ['[Thanh toán là m2]',
      'Giá tổng = Giá x Diện tích',
      '[Thanh toán là tổng/tháng]',
      'Giá tổng = Giá nhập',
      '[Diện tích]',
      'Diện tích = Ngang x Dài x (Lầu + 1)',
    ].join('<br/>')

    const address = reactive({
      province_id: computed(() => props.value.province_id),
      district_id: computed(() => props.value.district_id),
      product_type: ref('office'),
      formality: computed(() => props.value.formality),
      ward_id: computed(() => props.value.ward_id),
      street_id: computed(() => props.value.street_id),
      house_number: computed(() => props.value.house_number),
      type: computed(() => props.value.type),
    })
    const addressStatus = ref(undefined) // [undefined, '', error]
    const duplicateError = ref('')

    return {
      duplicateError,
      required,
      address,
      users,
      tooltipPriceDisplayCustomer,
      productMenuTypeVI,
      addressStatus,
      districts,
      wards,
      streets,
      //
      checkProductUnique,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/quill.scss';
</style>
